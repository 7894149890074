import React, { useState } from "react";
import { Button, Header, Image, Segment, Table } from "semantic-ui-react";

function DiagnosticTable({ diagnostics, jumpToPosition }) {
  const [showErrors, setShowErrors] = useState(true);
  const severity = (severity) => {
    let icon;
    switch (severity) {
      case 0:
        icon = "error";
        break;
      case 1:
        icon = "warning";
        break;
      case 2:
        icon = "hint";
        break;
      case 3:
        icon = "info";
        break;
      default:
        icon = "error";
    }
    return (
      <Image
        src={`/custom-content/${icon}.png`}
        centered
        title={`${icon}`}
        alt={`${icon}`}
        width="13"
        height="13"
      />
    );
  };
  return (
    <Segment clearing>
      <Header as="h2" floated="left">
        Please fix the errors
      </Header>
      <Button
        negative
        size="large"
        floated="right"
        onClick={() => setShowErrors((showErrors) => !showErrors)}
      >
        {`${showErrors ? "Hide" : "Show"} errors`}
      </Button>
      {showErrors && (
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Severity</Table.HeaderCell>
              <Table.HeaderCell>Code</Table.HeaderCell>
              <Table.HeaderCell>Message</Table.HeaderCell>
              {diagnostics[0].range && (
                <Table.HeaderCell>Line Number</Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {diagnostics.map((error, index) => (
              <Table.Row key={index}>
                <Table.Cell>{severity(error.severity)}</Table.Cell>
                <Table.Cell>{error.code || error.keyword}</Table.Cell>
                <Table.Cell>
                  <div className="errMsg">{error.message}</div>
                  <div className="errInstancePath">{error.instancePath}</div>
                </Table.Cell>
                <Table.Cell>
                  <Button
                    title="Jump to line"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      jumpToPosition(
                        error?.range?.start?.line,
                        error?.range?.start?.character
                      )
                    }
                  >
                    {error.range?.start
                      ? error.range.start.line +
                        ":" +
                        error.range.start.character
                      : "n/a"}
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </Segment>
  );
}

export default DiagnosticTable;
