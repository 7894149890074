import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import _ from "lodash";
import { useForm } from "react-hook-form";

import { fetchApisAndBasePaths } from "services/self-service";
import { store } from "services/state";
import { convertOptions } from "utils/utility";
import { API_DOMAIN, BASE_PATH, NAME } from "utils/constants";

function APICreationForm({
  openApiCreationForm,
  apiDetails,
  handleNewApi,
  handleCloseForm,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
    clearErrors,
  } = useForm({ defaultValues: apiDetails });

  const { apiDomain, name, basePath, description } = apiDetails;
  const { apisAndBasePathsList } = store;
  const [existingApisAndBasePaths, setExistingApisAndBasePaths] =
    useState(apisAndBasePathsList);

  const fetchApiBasePaths = useCallback(async () => {
    const { data } = await fetchApisAndBasePaths();
    store.apisAndBasePathsList = data;
    setValue(API_DOMAIN, Object.keys(data)[0]);
    setExistingApisAndBasePaths(data);
  }, [setValue]);

  useEffect(() => {
    setValue(API_DOMAIN, apiDomain || Object.keys(apisAndBasePathsList)[0]);
    if (apisAndBasePathsList.length === 0) {
      fetchApiBasePaths();
    } else {
      setExistingApisAndBasePaths(apisAndBasePathsList);
    }
  }, [apiDomain, apisAndBasePathsList, fetchApiBasePaths, setValue]);

  const validateApiName = (api) => {
    if (existingApisAndBasePaths[getValues(API_DOMAIN)].name.includes(api)) {
      return "API Name already exists";
    }
    return true;
  };

  const validateBasePath = (basePath) => {
    if (
      existingApisAndBasePaths[getValues(API_DOMAIN)].basePath.includes(
        basePath
      )
    ) {
      return "Base path already exists";
    }
    return true;
  };

  const handleInput = (input) => {
    const { name, value } = input;
    setValue(name, value.trim(), {
      shouldValidate: true,
    });
    if (name === API_DOMAIN) {
      trigger([NAME, BASE_PATH]);
    }
  };

  const handleClose = (e) => {
    clearErrors();
    e.preventDefault();
    handleCloseForm();
  };

  return (
    <Modal open={openApiCreationForm}>
      <Modal.Header>API Creation</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Select
              name={API_DOMAIN}
              label="Domain"
              placeholder="Select the domain"
              value={
                getValues(API_DOMAIN) || Object.keys(apisAndBasePathsList)[0]
              }
              onChange={(e, input) => handleInput(input)}
              options={convertOptions(Object.keys(existingApisAndBasePaths))}
              refs={register(API_DOMAIN)}
            />
            <Form.Input
              name={NAME}
              label="API Name"
              placeholder="Enter the API name"
              defaultValue={name}
              disabled={_.isEmpty(apisAndBasePathsList)}
              onChange={(e, input) => handleInput(input)}
              refs={register(NAME, {
                required: "API Name is required",
                pattern: {
                  value: /^(?![-_])(?!.*[-_]{2})[a-zA-Z0-9-_]+(?<![-_])$/,
                  message:
                    "Only alphanumericals and non consecutive - and _ are allowed and should not start or end with hyphen",
                },
                validate: (api) => validateApiName(api),
              })}
              error={errors.name ? { content: errors.name.message } : false}
            />
            <Form.Input
              name={BASE_PATH}
              label="Base path"
              placeholder="Enter the base path"
              defaultValue={basePath}
              disabled={_.isEmpty(apisAndBasePathsList)}
              onChange={(e, input) => handleInput(input)}
              refs={register(BASE_PATH, {
                required: "Base path is required",
                pattern: {
                  value: /^(?!-)(?!.*--)[a-zA-Z0-9-]+(?<!-)$/,
                  message:
                    "Only alphanumericals and non consecutive hyphens are allowed and should not start or end with hyphen",
                },
                validate: (basePath) => validateBasePath(basePath),
              })}
              error={
                errors.basePath ? { content: errors.basePath.message } : false
              }
            />
          </Form.Group>
          <Form.TextArea
            name="description"
            label="Description"
            placeholder="Enter description"
            defaultValue={description}
            onChange={(e, input) => handleInput(input)}
            refs={register("description", {
              required: "Description is required",
              maxLength: {
                value: 300,
                message: "Description should be less than 300 characters",
              },
            })}
            error={
              errors.description
                ? { content: errors.description.message }
                : false
            }
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Form.Group>
          <Button
            positive
            onClick={handleSubmit(() => handleNewApi(getValues()))}
          >
            Submit
          </Button>
          <Button negative onClick={(e) => handleClose(e)}>
            Close
          </Button>
        </Form.Group>
      </Modal.Actions>
    </Modal>
  );
}

export default APICreationForm;
