import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Card,
  Form,
  Table,
  Button,
  Image,
  Segment,
  Header,
  Icon,
  Checkbox,
  Confirm,
  Message,
  Modal,
  List,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import * as YAML from "yaml";
import CodeMirror from "@uiw/react-codemirror";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { useHistory, useLocation, withRouter } from "react-router-dom";

import SwaggerParser from "@apidevtools/swagger-parser";
import Parser from "@asyncapi/parser/browser/index";

import "./APIGWSelfService.css";
import APICreationForm from "./APICreationForm";
import EndPointDetailsForm from "./EndPointDetailsForm";
import DeleteEndpoint from "./DeleteEndpoint";

import { store } from "services/state";
import {
  fetchAllModuleNames,
  fetchEndpointDetails,
  submitCreateConfig,
  submitPromoteConfig,
} from "services/self-service";
import { convertOptions } from "utils/utility";
import { REST, WEBSOCKET } from "utils/constants";

function APIGWSelfService() {
  const {
    register,
    unregister,
    trigger,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const { search, pathname } = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(search);
  const apitype = params.get("apiType");
  const module = params.get("module");
  const api = params.get("api");
  const isNewApi = params.get("newApi");

  const DEV = process.env.REACT_APP_ENVIRONMENT_NAME === "dev";
  const IS_REST = apitype === REST;
  const IS_WEBSOCKET = apitype === WEBSOCKET;

  const [displayForm, setDisplayForm] = useState(false);
  const [openApiCreationForm, setOpenApiCreationForm] = useState(false);
  const [actionsModal, setActionsModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [moduleOptions, setModuleoptions] = useState(
    store.modules[apitype] || {}
  );
  const [apiDetails, setApiDetails] = useState({});
  const [userAction, setUserAction] = useState("");
  const [endpoints, setEndpoints] = useState([]);
  const [editedEndpoints, setEditedEndpoints] = useState([]);
  const [createdEndpoints, setCreatedEndpoints] = useState([]);
  const [selectedEndpoint, setSelectedEndpoint] = useState({});
  const [config, setConfig] = useState(null);
  const [isSingleUploader, setIsSingleUploader] = useState(false);
  const [response, setResponse] = useState({});

  const [loadingEndpoints, setLoadingEndpoints] = useState(false);
  const [loadingJenkins, setLoadingJenkins] = useState(false);

  const getModules = useCallback(
    async (apitype) => {
      if (editedEndpoints.length > 0) {
        return;
      }

      /**
       * Fetch the modules from the store.
       * If the store is empty, fetch the modules from the backend and store them.
       * */
      if (!_.isEmpty(store.modules[apitype])) {
        setModuleoptions(store.modules[apitype]);
        return store.modules[apitype];
      } else {
        setDisplayForm(true);
        const modules = await fetchAllModuleNames(apitype);
        store.modules[apitype] = modules;
        setModuleoptions(modules);
        return modules;
      }
    },
    [editedEndpoints.length]
  );

  const getEndpointDetails = useCallback(
    async (apiName) => {
      setLoadingEndpoints(true);
      try {
        const endpoints = await fetchEndpointDetails(apitype, module, apiName);
        const { endPointDetails, version, kind, ...apiDetails } = endpoints;
        setApiDetails(apiDetails);
        setEndpoints(endPointDetails.map((endpoint, index) => endpoint[index]));
      } catch (e) {
        setEndpoints([]);
      }
      setLoadingEndpoints(false);
    },
    [apitype, module]
  );

  const redirect = (searchParams) => {
    return history.replace({
      pathname,
      search: searchParams,
    });
  };

  const handleRedirect = useCallback(async () => {
    //If the query param apiType is valid, get the modules, otherwise redirect to home.
    if (IS_REST || IS_WEBSOCKET) {
      //If the query param module doesn't exist in the options, redirect to the API types.
      const modules = await getModules(apitype);
      if (!modules || !modules[module]) {
        return redirect(`?apiType=${apitype}`);
      }

      //If the query param newApi is true in dev, open the API creation form.
      if (isNewApi && DEV) {
        return setOpenApiCreationForm(
          api && !_.isEmpty(apiDetails) ? false : true
        );
      }
      openApiCreationForm && setOpenApiCreationForm(false);

      //If the query param api is invalid, fetch the endpoint details. Otherwise, redirect to the modules.
      if (!modules[module]?.includes(api)) {
        return redirect(`?apiType=${apitype}&module=${module}`);
      }
      await getEndpointDetails(api);
    } else {
      redirect("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, history]);

  useEffect(() => {
    handleRedirect();
  }, [handleRedirect]);

  const handleNewApi = (apiDetails) => {
    setApiDetails(apiDetails);
    setOpenApiCreationForm(false);
    setEndpoints([]);
    return redirect(
      `?apiType=${apitype}&module=${module}&newApi=true&api=${apiDetails.name}`
    );
  };

  const discardNewApi = () => {
    setEditedEndpoints([]);
    setApiDetails({});
    redirect(`?apiType=${apitype}&module=${module}`);
  };

  const handleCloseForm = () => {
    if (
      _.isEmpty(apiDetails) ||
      moduleOptions[module].includes(apiDetails.name)
    ) {
      redirect(`?apiType=${apitype}&module=${module}`);
    } else {
      setOpenApiCreationForm(false);
    }
  };

  const viewEndpoint = async (endpoint) => {
    setSelectedEndpoint(endpoint);
    setActionsModal(true);
    setUserAction("view");
  };

  const promoteEndpoint = (endpoint) => {
    setEditedEndpoints(
      editedEndpoints.concat({ ...endpoint, userAction: "promote" })
    );
  };

  const handleCheckBox = (endpoint, isChecked) => {
    if (isChecked) {
      if (endpoint.operation === "delete") {
        deleteEndpoint(endpoint);
      } else {
        promoteEndpoint(endpoint);
      }
    } else {
      clearEndpoint(endpoint);
    }
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      setEditedEndpoints(
        endpoints.map((endpoint) => ({
          ...endpoint,
          userAction: "promote",
        }))
      );
    } else {
      setEditedEndpoints([]);
    }
  };

  const createEndpoint = () => {
    if (IS_REST) {
      setSelectedEndpoint(DEFAULT_REST_CONFIG);
      setUserAction("create");
      setActionsModal(true);
    } else {
      setSelectedEndpoint(DEFAULT_WEBSOCKET_CONFIG);
      setUserAction("create");
      setActionsModal(true);
    }
  };

  const updateEndpoint = (endpoint) => {
    setSelectedEndpoint({ ...endpoint, operation: "create" });
    setUserAction("edit");
    setActionsModal(true);
  };

  //Edit the newly created endpoint before building the pipeline
  const editNewEndpoint = (endpoint) => {
    setSelectedEndpoint(endpoint);
    setUserAction("create");
    setActionsModal(true);
  };

  const deleteEndpoint = (endpoint) => {
    setSelectedEndpoint({
      ...endpoint,
      operation: "delete",
      userAction: "delete",
    });
    setConfirmDelete(true);
  };

  const getEditedEndpoint = (endpoint) => {
    return editedEndpoints.find(
      (endPointDetails) =>
        generateEndpointName(endPointDetails) === generateEndpointName(endpoint)
    );
  };

  const clearEndpoint = (endpoint) => {
    unregister(generateEndpointName(endpoint));
    setEditedEndpoints(
      editedEndpoints.filter(
        (endPointDetails) =>
          generateEndpointName(endPointDetails) !==
          generateEndpointName(endpoint)
      )
    );
  };

  const handleFile = async (event, name) => {
    const file = event.target.files[0];
    setValue(name, file);
    await trigger(name);

    if (!errors[name]) {
      const content = await readFile(file);
      const json =
        file.type === "application/json"
          ? JSON.parse(content)
          : YAML.parse(content);

      if (name === "singleFile") {
        setEditedEndpoints(
          editedEndpoints.map((endpoint) => ({
            ...endpoint,
            specification: {
              file: JSON.stringify(json),
              fileType: file.name.split(".").pop(),
            },
          }))
        );
      } else {
        setEditedEndpoints(
          editedEndpoints.map((endpoint) => {
            if (generateEndpointName(endpoint) === name) {
              return {
                ...endpoint,
                specification: {
                  ...endpoint.specification,
                  file: JSON.stringify(json),
                  fileType: file.name.split(".").pop(),
                },
              };
            } else {
              return endpoint;
            }
          })
        );
      }
    }
  };

  const cleanupEndpointConfig = (endpoint) => {
    if (!endpoint.timeoutInSeconds) {
      delete endpoint.timeoutInSeconds;
    } else if (!isNaN(endpoint.timeoutInSeconds)) {
      endpoint.timeoutInSeconds = Number(endpoint.timeoutInSeconds);
    }
    if (!endpoint.authorizationDetails.authName) {
      delete endpoint.authorizationDetails.authName;
    }
    return endpoint;
  };

  const cleanupConfig = (config) => {
    config.endPointDetails.forEach((endpoints) => {
      Object.values(endpoints).forEach((endpoint) => {
        cleanupEndpointConfig(endpoint);
      });
    });
  };

  const checkIfEdited = (endpoint) => {
    if (!editedEndpoints.length) {
      return false;
    }

    return editedEndpoints.some(
      (endPointDetails) =>
        generateEndpointName(endPointDetails) === generateEndpointName(endpoint)
    );
  };

  const checkIfDeleted = (endpoint) => {
    if (
      checkIfEdited(endpoint) &&
      getEditedEndpoint(endpoint).operation === "delete"
    ) {
      return true;
    } else return false;
  };

  useEffect(() => {
    //Comparing the edited endpoints with existing ones to check if the endpoint is really edited or not
    editedEndpoints.map((endPointDetails) =>
      // eslint-disable-next-line array-callback-return
      endpoints.map((endpoint) => {
        if (
          _.isEqual(
            endpoint,
            cleanupEndpointConfig(_.omit(endPointDetails, "specification"))
          )
        ) {
          setEditedEndpoints(
            editedEndpoints.filter(
              (endPointDetail) =>
                !(
                  generateEndpointName(endPointDetail) ===
                  generateEndpointName(endpoint)
                )
            )
          );
        }
      })
    );

    //To display the created endpoint
    setCreatedEndpoints(
      editedEndpoints.filter((endpoint) => endpoint.userAction === "create")
    );

    //Check if a single swagger file is needed
    const checkIfSingleFile = () => {
      if (IS_WEBSOCKET) return false;
      if (editedEndpoints.length < 2) return false;

      //Check if all the endpoints are based on lambda or lambda_proxy integration
      const isLambdaIntegration = editedEndpoints.every((endpoint) =>
        /lambda/i.test(endpoint.integrationType)
      );

      //Check if all the inputs have the same lambda ARN
      const isSameArn = () => {
        return editedEndpoints.every(
          (endpoint) =>
            endpoint.backendDetails.uri ===
            editedEndpoints[0].backendDetails.uri
        );
      };

      //Check if all the endpoints are based in the same integration type
      const isSameIntegrationType = () => {
        return editedEndpoints.every(
          (endpoint) =>
            endpoint.integrationType === editedEndpoints[0].integrationType
        );
      };

      //Get the basepath of the HTTPS URI along with the domain and port
      const getBasePath = (uri) => {
        const url = new URL(uri);
        return url.host.concat(url.pathname.split("/")[1]);
      };

      if (isLambdaIntegration) {
        return isSameArn();
      } else if (isSameIntegrationType()) {
        return editedEndpoints.every(
          (endpoint) =>
            getBasePath(endpoint.backendDetails.uri) ===
            getBasePath(editedEndpoints[0].backendDetails.uri)
        );
      } else {
        return false;
      }
    };

    setIsSingleUploader(checkIfSingleFile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedEndpoints, endpoints, apitype]);

  useEffect(() => {
    if (!isSingleUploader) {
      unregister("singleFile"); //Unregistering the single file input field from react hook form
    } else {
      const allFields = Object.keys(getValues());
      unregister(allFields.filter((field) => field !== "singleFile")); //Unregistering all the file input fields except the single file input field
    }
  }, [isSingleUploader, unregister, getValues]);

  useEffect(() => {
    const endPointDetails = editedEndpoints.map((endpoint, index) => ({
      [index]: _.omit(endpoint, ["userAction", "specification"]),
    }));

    const config = {
      version: "1.0.0",
      kind: "api_gw_selfservice",
      apitype: apitype,
      moduleName: module,
      name: api,
      ...apiDetails,
      endPointDetails: endPointDetails,
    };
    endPointDetails.forEach((endpoints) => {
      Object.values(endpoints).forEach((endpoint) => {
        cleanupEndpointConfig(endpoint);
      });
    });
    YAML.stringify(config);
    setConfig(config);
  }, [api, apiDetails, apitype, editedEndpoints, endpoints, module]);

  const generateEndpointName = (endpoint) => {
    if (IS_REST) {
      return (
        endpoint.backendDetails.httpMethod +
        "/" +
        endpoint.resourceVersion +
        endpoint.resourcePath
      );
    } else if (IS_WEBSOCKET) return endpoint.action;
  };

  const validateFileExtension = (file) => {
    const allowedFileFormats = ["json", "yml", "yaml"];
    try {
      const fileFormat = file.name.split(".").pop();
      return allowedFileFormats.includes(fileFormat);
    } catch (e) {
      return false;
    }
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsText(file);
      fileReader.onload = (event) => {
        const data = event.target.result;
        resolve(data);
      };
    });
  };

  const validateSwagger = async (content) => {
    try {
      await SwaggerParser.validate(content);
      return true;
    } catch (e) {
      return "The uploaded file is not a valid Swagger documentation";
    }
  };

  const validateAsyncAPI = async (content) => {
    const parser = new Parser();
    if (content["x-parser-spec-parsed"] === true) {
      content["x-parser-spec-parsed"] = false;
    }
    const { document } = await parser.parse(content);
    if (document) {
      return true;
    } else {
      return "The uploaded file is not a valid Async API documentation";
    }
  };

  const validateApiType = (content) => {
    if (IS_REST && (content.swagger || content.openapi)) {
      return validateSwagger(content);
    } else if (IS_WEBSOCKET && content.asyncapi) {
      return validateAsyncAPI(content);
    }
  };

  const validateFile = async (file) => {
    const data = await readFile(file);
    try {
      const content =
        file.type === "application/json" ? JSON.parse(data) : YAML.parse(data);
      return await validateApiType(content);
    } catch (e) {
      return "Uploaded file is not a valid json or yaml";
    }
  };

  const isValidUrl = (string) => {
    try {
      return string.includes("https");
    } catch (e) {
      return false;
    }
  };

  const isTriggered = (endpoint, response) => {
    return response.some(
      (endpointResponse) =>
        endpointResponse.endpointName === generateEndpointName(endpoint) &&
        isValidUrl(endpointResponse.message)
    );
  };

  const submitInputs = async () => {
    setConfirmBox(false);
    setLoadingJenkins(true);
    const endPointDetails = editedEndpoints.map((endpoint, index) => ({
      [index]: _.omit(endpoint, "userAction"),
    }));

    const createConfig = {
      version: "1.0.0",
      kind: "api_gw_selfservice",
      apitype: apitype,
      moduleName: module,
      name: api,
      endPointDetails: endPointDetails,
      userIdentity: store.user?.email,
    };
    if (IS_REST) {
      Object.assign(createConfig, apiDetails); //New API creation is supported only for REST APIs
    }

    cleanupConfig(createConfig);

    const promoteConfig = IS_REST
      ? editedEndpoints
          .filter(
            ({ userAction }) =>
              userAction === "promote" || userAction === "delete"
          )
          .map((endpoint) => ({
            moduleName: module,
            apiName: api,
            apiDomain: apiDetails.apiDomain,
            basePath: apiDetails.basePath,
            description: apiDetails.description,
            resourcePath: endpoint.resourcePath,
            resourceVersion: endpoint.resourceVersion,
            httpMethod: endpoint.backendDetails.httpMethod,
            userIdentity: store.user?.email,
          }))
      : editedEndpoints
          .filter(
            ({ userAction }) =>
              userAction === "promote" || userAction === "delete"
          )
          .map((endpoint) => ({
            moduleName: module,
            apiName: api,
            action: endpoint.action,
            userIdentity: store.user?.email,
          }));

    //Send the config to the backend
    const response = DEV
      ? await submitCreateConfig(apitype, createConfig)
      : await submitPromoteConfig(apitype, promoteConfig);

    if (!response.error) {
      //The newly created endpoints for which the Jenkins pipelines are successfully triggered will be added to the existing endpoints
      setEndpoints([
        ...endpoints,
        ...createdEndpoints
          .filter((endpoint) => isTriggered(endpoint, response.message))
          .map(({ userAction, ...rest }) => rest),
      ]);

      //The endpoints for which the Jenkins pipelines are successfully triggered will stay in the inputs
      setEditedEndpoints(
        editedEndpoints.filter(
          (endpoint) => !isTriggered(endpoint, response.message)
        )
      );
    }
    setResponse(response);
    setLoadingJenkins(false);
  };

  const cards = (
    <Card.Group>
      <Card
        data-testid="card"
        style={{ background: `${IS_REST ? "#eb9b34" : "white"}` }}
        raised={IS_REST}
        onClick={() => {
          if (editedEndpoints.length === 0) {
            redirect(`?apiType=${REST}`);
          }
        }}
      >
        <Card.Content>
          <Card.Header>REST API</Card.Header>
          <Card.Description textAlign="center">
            <Image
              width="50"
              height="50"
              centered
              src="/custom-content/openapi-logo.png"
            />
          </Card.Description>
          <Card.Description textAlign="center">
            Click here to view, create, update or delete the REST API endpoints
          </Card.Description>
        </Card.Content>
      </Card>
      <Card
        data-testid="card"
        style={{
          background: `${IS_WEBSOCKET ? "#eb9b34" : "white"}`,
        }}
        raised={IS_WEBSOCKET}
        onClick={() => {
          if (editedEndpoints.length === 0) {
            redirect(`?apiType=${WEBSOCKET}`);
          }
        }}
      >
        <Card.Content>
          <Card.Header>WebSocket API</Card.Header>
          <Card.Description textAlign="center">
            <Image
              width="50"
              height="50"
              centered
              src="/custom-content/async-api-logo.png"
            />
          </Card.Description>
          <Card.Description textAlign="center">
            Click here to view, create, update or delete the WebSocket API
            endpoints
          </Card.Description>
        </Card.Content>
      </Card>
    </Card.Group>
  );

  const fileUploader = (name) => (
    <Form.Input
      label="API specification file"
      refs={register(name, {
        required: "Please upload the API specification file",
        validate: {
          extension: (file) =>
            validateFileExtension(file) || "File should be either json or yaml",
          file: async (file) =>
            (await validateFile(file)) ||
            `Please upload a valid ${IS_REST ? "Swagger" : "Async API"} file`,
        },
      })}
      type="file"
      accept="application/json,.yaml,.yml"
      onChange={(event) => handleFile(event, name)}
      onClick={(event) => (event.target.value = null)}
      error={errors[name] ? { content: errors[name].message } : false}
      placeholder="Upload file"
    ></Form.Input>
  );

  const getBuildNumber = (url) => {
    const split = url.split("/");
    return split[split.length - 1];
  };

  const getJenkinsResponse = (endpoint) => {
    const endpointResponse = response.message.find(
      (res) => res.endpointName === generateEndpointName(endpoint)
    );
    if (endpointResponse) {
      return isValidUrl(endpointResponse.message) ? (
        <Button color="blue" href={endpointResponse.message} target="blank">
          {`Build #${getBuildNumber(endpointResponse.message)}`}
        </Button>
      ) : (
        <>
          <Icon name="warning sign" /> {endpointResponse.message}
        </>
      );
    }
  };

  return (
    <Container
      style={{
        padding: "2em",
        overflow: "auto",
        height: "100%",
        width: "100%",
        background: "#292a34",
      }}
    >
      <Dimmer active={loadingJenkins}>
        <Loader size="massive" content="Building Jenkins pipeline" />
      </Dimmer>
      <Segment>
        <Header>API GW Self-Service</Header>
        <Header.Subheader>
          This is to enable modules to create and modify API endpoints on AWS
          API Gateway independently without PR approval requirement.
        </Header.Subheader>
      </Segment>
      <Segment>
        <Header>1. Select the API type</Header>
        {cards}
      </Segment>
      {displayForm && (
        <Form data-testid="form" error={response.error}>
          <Segment>
            <Header>2. Select the module name and API name</Header>
            <Form.Group style={{ paddingTop: "2em 0" }}>
              <Form.Select
                id="html"
                name="moduleName"
                label="Module name"
                placeholder="Select Module name"
                value={module}
                search
                options={convertOptions(Object.keys(moduleOptions))}
                disabled={editedEndpoints.length > 0}
                onChange={(e, { value }) =>
                  redirect(`?apiType=${apitype}&module=${value}`)
                }
              />
              <Form.Select
                id="html"
                name="name"
                label="API name"
                placeholder="Select API name"
                value={api}
                search
                options={
                  isNewApi
                    ? convertOptions([apiDetails.name])
                    : convertOptions(moduleOptions[module] || [])
                }
                disabled={editedEndpoints.length > 0}
                onChange={(e, { value }) =>
                  redirect(`?apiType=${apitype}&module=${module}&api=${value}`)
                }
              />
              {DEV && IS_REST && (
                <Button.Group>
                  {!isNewApi ? (
                    <Button
                      style={{ marginTop: "1.75em" }}
                      positive
                      disabled={
                        module === null ||
                        (!isNewApi && editedEndpoints.length > 0)
                      }
                      onClick={() =>
                        redirect(
                          `?apiType=${apitype}&module=${module}&newApi=true`
                        )
                      }
                    >
                      <Icon name="plus" />
                      Create new API
                    </Button>
                  ) : (
                    <>
                      <Button
                        icon="edit"
                        title="Edit the new API"
                        color="blue"
                        style={{ marginTop: "1.75em" }}
                        onClick={() => setOpenApiCreationForm(true)}
                      ></Button>
                      <Button
                        icon="cancel"
                        title="Discard the new API"
                        negative
                        style={{ marginTop: "1.75em" }}
                        onClick={discardNewApi}
                      />
                    </>
                  )}
                </Button.Group>
              )}
            </Form.Group>
          </Segment>
          {api && (
            <Segment>
              <Header>
                {DEV
                  ? "3. Click on the required action"
                  : "3. Select the endpoint(s) to promote"}
              </Header>
              <Dimmer active={loadingEndpoints}>
                <Loader />
              </Dimmer>
              <Table data-testid="table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{ width: "3%" }}>
                      <Checkbox
                        data-testid="select-all-checkbox"
                        disabled={DEV}
                        onChange={(e, { checked }) => handleSelectAll(checked)}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {IS_REST ? "Endpoint name" : "Action name"}
                    </Table.HeaderCell>
                    <Table.HeaderCell>HTTP Method</Table.HeaderCell>
                    {IS_REST && (
                      <Table.HeaderCell>Endpoint version</Table.HeaderCell>
                    )}
                    <Table.HeaderCell>Action</Table.HeaderCell>
                    {!isSingleUploader && editedEndpoints.length > 0 && (
                      <Table.HeaderCell>API specification</Table.HeaderCell>
                    )}
                    {_.isArray(response.message) && (
                      <Table.HeaderCell>Jenkins Response</Table.HeaderCell>
                    )}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {endpoints.map((endpoint, index) => (
                    <Table.Row
                      key={index}
                      id={`${
                        checkIfEdited(endpoint)
                          ? checkIfDeleted(endpoint)
                            ? "delete"
                            : "edit"
                          : ""
                      }`}
                    >
                      <Table.Cell style={{ width: "3%" }}>
                        <Checkbox
                          disabled={DEV && !checkIfEdited(endpoint)}
                          checked={editedEndpoints.some(
                            (endPointDetails) =>
                              generateEndpointName(endPointDetails) ===
                              generateEndpointName(endpoint)
                          )}
                          onChange={(e, { checked }) =>
                            handleCheckBox(endpoint, checked)
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {endpoint.action || endpoint.resourcePath}
                      </Table.Cell>
                      <Table.Cell>
                        {endpoint.backendDetails.httpMethod}
                      </Table.Cell>
                      {IS_REST && (
                        <Table.Cell>{endpoint.resourceVersion}</Table.Cell>
                      )}
                      <Table.Cell data-testid="actions cell" singleLine>
                        <Button
                          title="View"
                          positive
                          icon="eye"
                          role="view"
                          onClick={() => viewEndpoint(endpoint)}
                        ></Button>
                        {DEV && (
                          <Button
                            title="Edit"
                            color="blue"
                            icon="edit"
                            role="edit"
                            disabled={checkIfDeleted(endpoint)}
                            onClick={() =>
                              updateEndpoint(
                                checkIfEdited(endpoint)
                                  ? getEditedEndpoint(endpoint)
                                  : endpoint
                              )
                            }
                          ></Button>
                        )}
                        {DEV || endpoint.operation === "delete" ? (
                          <Button
                            title="Delete"
                            negative
                            inverted
                            icon="trash"
                            role="delete"
                            disabled={DEV && checkIfEdited(endpoint)}
                            onClick={() => deleteEndpoint(endpoint)}
                          ></Button>
                        ) : (
                          <Button
                            title="Deploy"
                            color="blue"
                            icon="cloud upload"
                            role="deploy"
                            disabled={checkIfEdited(endpoint)}
                            onClick={() => promoteEndpoint(endpoint)}
                          ></Button>
                        )}
                        {checkIfEdited(endpoint) && (
                          <Button
                            title="Discard change"
                            negative
                            inverted
                            icon="cancel"
                            role="discard"
                            onClick={() => clearEndpoint(endpoint)}
                          />
                        )}
                      </Table.Cell>
                      {!isSingleUploader && editedEndpoints.length > 0 && (
                        <Table.Cell>
                          {checkIfEdited(endpoint) &&
                          !checkIfDeleted(endpoint) &&
                          DEV
                            ? fileUploader(generateEndpointName(endpoint))
                            : "-"}
                        </Table.Cell>
                      )}
                      {_.isArray(response.message) && (
                        <Table.Cell>{getJenkinsResponse(endpoint)}</Table.Cell>
                      )}
                    </Table.Row>
                  ))}
                  {createdEndpoints.map((endpoint, index) => (
                    <Table.Row key={index} id="create">
                      <Table.Cell style={{ width: "3%" }}>
                        <Checkbox
                          checked={editedEndpoints.some(
                            (endPointDetails) =>
                              generateEndpointName(endPointDetails) ===
                              generateEndpointName(endpoint)
                          )}
                          onChange={(e, { checked }) =>
                            handleCheckBox(endpoint, checked)
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {endpoint.resourcePath || endpoint.action}
                      </Table.Cell>
                      <Table.Cell>
                        {endpoint.backendDetails.httpMethod}
                      </Table.Cell>
                      {IS_REST && (
                        <Table.Cell>
                          {endpoint.resourceVersion || "v1"}
                        </Table.Cell>
                      )}
                      <Table.Cell data-testid="actions cell" singleLine>
                        <Button
                          title="View"
                          positive
                          inverted
                          icon="eye"
                          role="view"
                          onClick={() => viewEndpoint(endpoint)}
                        ></Button>
                        <Button
                          title="Edit"
                          color="blue"
                          icon="edit"
                          role="edit"
                          onClick={() => editNewEndpoint(endpoint)}
                        ></Button>
                        <Button
                          title="Cannot delete new endpoint"
                          negative
                          role="delete"
                          icon="trash"
                          disabled
                        ></Button>
                        <Button
                          title="Discard change"
                          negative
                          icon="cancel"
                          role="discard"
                          onClick={() => clearEndpoint(endpoint)}
                        />
                      </Table.Cell>
                      {!isSingleUploader && DEV && (
                        <Table.Cell>
                          {fileUploader(generateEndpointName(endpoint))}
                        </Table.Cell>
                      )}
                      {_.isArray(response.message) && (
                        <Table.Cell>{getJenkinsResponse(endpoint)}</Table.Cell>
                      )}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              {DEV && (
                <Button role="create" positive onClick={() => createEndpoint()}>
                  + Create new endpoint
                </Button>
              )}
              {actionsModal && (
                <EndPointDetailsForm
                  actionsModal={actionsModal}
                  setActionsModal={setActionsModal}
                  apiType={apitype}
                  module={module}
                  existingEndpoints={endpoints}
                  selectedEndpoint={selectedEndpoint}
                  userAction={userAction}
                  editedEndpoints={editedEndpoints}
                  setEditedEndpoints={setEditedEndpoints}
                  generateEndpointName={generateEndpointName}
                />
              )}
              {confirmDelete && (
                <DeleteEndpoint
                  confirmDelete={confirmDelete}
                  setConfirmDelete={setConfirmDelete}
                  endpoint={selectedEndpoint}
                  setEditedEndpoints={setEditedEndpoints}
                />
              )}
            </Segment>
          )}
          {isSingleUploader && DEV && (
            <Segment>
              <Header>4. Upload the API specification file</Header>
              {fileUploader("singleFile")}
            </Segment>
          )}
          {response.error && (
            <Message
              error={response.error}
              header={
                <Header>
                  The pipeline cannot be triggered because of the below errors
                </Header>
              }
              content={
                <List bulleted>
                  {response.message.map((msg, index) => (
                    <List.Item key={index}>
                      <b>{msg}</b>
                    </List.Item>
                  ))}
                </List>
              }
              onDismiss={() => setResponse({})}
              data-testid="validationMessage"
            ></Message>
          )}
          {editedEndpoints.length > 0 && (
            <Segment>
              <Header>
                {`${
                  isSingleUploader && DEV ? "5" : "4"
                }. Build the SSv3 Jenkins Pipeline`}
              </Header>
              <Form.Group>
                <Form.Button
                  icon
                  labelPosition="left"
                  disabled={editedEndpoints.length === 0}
                  color="green"
                  onClick={handleSubmit(() => setConfirmBox(true))}
                >
                  <Icon name="play" />
                  Build Now
                </Form.Button>
              </Form.Group>
            </Segment>
          )}
        </Form>
      )}
      {editedEndpoints.length > 0 && (
        <Segment>
          <Header>API Endpoint Configuration Details</Header>
          To manually build the SSv3 Jenkins pipeline with build parameters,
          please copy the generated configuration YAML file
          {editedEndpoints.length > 0 && (
            <Form.Button
              onClick={() =>
                navigator.clipboard.writeText(YAML.stringify(config, null, 1))
              }
            >
              Copy
            </Form.Button>
          )}
          <CodeMirror
            style={{ marginTop: "10px" }}
            theme={"dark"}
            width="100%"
            maxWidth="100%"
            height="500px"
            value={YAML.stringify(config, { options: { simpleKeys: true } })}
            editable={false}
          />
        </Segment>
      )}
      <Confirm
        open={confirmBox}
        content="Are you sure you want to build the Self Service Jenkins pipeline?"
        cancelButton="Back"
        onConfirm={submitInputs}
        onCancel={() => setConfirmBox(false)}
      />
      <Modal open={errorModal}>
        <Modal.Header>Error</Modal.Header>
        <Modal.Content>No data available for this endpoint</Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setErrorModal(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
      {openApiCreationForm && (
        <APICreationForm
          openApiCreationForm={openApiCreationForm}
          setOpenApiCreationForm={setOpenApiCreationForm}
          apiDetails={
            moduleOptions[module].includes(apiDetails.name) ? {} : apiDetails
          }
          handleNewApi={handleNewApi}
          handleCloseForm={handleCloseForm}
        />
      )}
    </Container>
  );
}

export default withRouter(APIGWSelfService);

const DEFAULT_REST_CONFIG = {
  integrationType: "",
  operation: "create",
  resourcePath: "",
  resourceVersion: "v1",
  productCode: "",
  backendDetails: {
    uri: "",
    httpMethod: "",
  },
  authorizationDetails: {},
  userAction: "create",
};

const DEFAULT_WEBSOCKET_CONFIG = {
  integrationType: "https-private",
  operation: "create",
  action: "",
  productCode: "",
  backendDetails: {
    uri: "",
    httpMethod: "",
  },
  authorizationDetails: {},
  userAction: "create",
};
