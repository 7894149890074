import { apiGatewayClientWithCredentials as getApiGatewayClient } from "services/api";

export const fetchFileNames = async (apiType) => {
  const apiGatewayClient = await getApiGatewayClient();
  try {
    const response = await apiGatewayClient.get(
      `/editor/get-file-names/${apiType}`,
      {},
      {},
      {}
    );

    return response.data;
  } catch (e) {
    return { fileNames: [] };
  }
};

export const fetchFileContent = async (apiType, fileName) => {
  const apiGatewayClient = await getApiGatewayClient();
  try {
    const response = await apiGatewayClient.get(
      `/editor/get-content/${apiType}/${fileName}`,
      {},
      {},
      {}
    );

    return response.data;
  } catch (e) {
    return { fileNames: [] };
  }
};

export const validateEditorContent = async (content, fileType) => {
  const apiGatewayClient = await getApiGatewayClient();
  try {
    const response = await apiGatewayClient.post(
      `/editor/validate-content`,
      {},
      {
        content: content,
        fileType: fileType,
      },
      {}
    );
    return response.data;
  } catch (e) {
    return e.data;
  }
};

export const submitEditorContent = async (
  content,
  fileName,
  userAction,
  fileType,
  userId
) => {
  const apiGatewayClient = await getApiGatewayClient();
  try {
    const response = await apiGatewayClient.post(
      `/editor/store-content`,
      {},
      {
        fileName: fileName,
        content: content,
        userAction: userAction,
        fileType: fileType,
        userId: userId,
      },
      {}
    );

    return response.data;
  } catch (e) {
    return e.data;
  }
};
